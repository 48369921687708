<template>
    <div
        class="transfer-activity"
        data-cy="transferActivity"
    >
        <div class="activity-body">
            <div
                v-if="loading"
                class="loading-indicator"
                data-cy="loadingIndicator"
            >
                <FontAwesomeIcon
                    fas
                    icon="spinner"
                    pulse
                />

                loading...
            </div>

            <template v-else>
                <div class="flex-row">
                    <span class="activity-title">
                        "{{ transfer.title }}" transfer {{ activity.success ? 'ran successfully' : 'failed while running' }}
                    </span>

                    <span class="activity-text mt-1 float-right">
                        {{ date(activity.createdAt) }}
                    </span>
                </div>

                <div
                    v-if="activity.explanation"
                    class="activity-explanation text-muted"
                    data-cy="activityExplanation"
                >
                    {{ activity.explanation }}
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import filtersMixin from '@imt/vue-toolbox/src/mixins/filters';
    import isEmpty from 'lodash/isEmpty';
    import {mapActions, mapState} from 'vuex';

    export default {
        name: 'PTransferActivity',
        props: {
            activity: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                loading: false,
                transfer: {},
            };
        },
        computed: {
            ...mapState([
                'transfers',
            ]),
        },
        async created() {
            if (!this.transfers.length) {
                this.loading = true;
                await this.fetchTransfers();
                this.loading = false;
            }

            this.transfer = this.transfers.find(t => t.id == this.activity.transferId) || {}; // eslint-disable-line eqeqeq
        },
        methods: {
            date: filtersMixin.filters.date,
            isEmpty,
            ...mapActions([
                'fetchTransfers',
            ]),
        },
    };
</script>

<style
    lang="sass"
    scoped
>
    .transfer-activity
        background-color: var(--card-background-color)
        border: 1px solid var(--card-border-color)
        border-radius: 0.375rem
        font-size: 1.25rem

        .activity-body
            color: var(--card-color)
            align-items: center
            justify-content: space-between
            padding: 2rem

            .activity-title
                font-size: 1.5rem
                font-weight: normal

            .activity-text
                margin-bottom: 0

        .activity-explanation
            margin-right: 0.25rem
            font-size: 1.25rem

        + .transfer-activity
            border-top: none
            border-top-left-radius: 0
            border-top-right-radius: 0

        &:not(:last-of-type)
            border-bottom-left-radius: 0
            border-bottom-right-radius: 0
</style>
