<template>
    <div
        class="container-fluid core-view d-flex flex-column transfer-activity-list"
        data-cy="transferActivityList"
    >
        <div class="activity-details">
            <div
                v-if="loading"
                class="loading-indicator"
                data-cy="loadingIndicator"
            >
                <FontAwesomeIcon
                    fas
                    icon="spinner"
                    pulse
                />

                loading...
            </div>

            <div
                v-else-if="transferActivity.length"
                class="activity"
            >
                <PTransferActivity
                    v-for="activity in transferActivity"
                    :key="activity.id"
                    :activity="activity"
                />

                <div
                    v-if="totalRows > 5"
                    class="activity-footer d-flex m-4"
                >
                    <Pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        type="traditional"
                        align="center"
                        size="md"
                        class="activity-pagination w-50 m-auto"
                        data-cy="activityPagination"
                    />

                    <div
                        v-if="fetching"
                        class="fetching-indicator"
                        data-cy="fetchingIndicator"
                    >
                        <FontAwesomeIcon
                            fas
                            icon="spinner"
                            pulse
                        />

                        loading...
                    </div>
                </div>
            </div>

            <div
                v-else
                class="activity no-activity text-muted"
                data-cy="noActivity"
            >
                No recent activity found for transfer.
            </div>
        </div>
    </div>
</template>

<script>
    import Pagination from '@imt/vue-kit-car/src/components/table/Pagination.vue';
    import utils from '@imt/vue-toolbox/src/utils';
    import {mapActions} from 'vuex';

    import PTransferActivity from '@/components/PTransferActivity.vue';

    export default {
        name: 'PTransferActivityList',
        components: {
            PTransferActivity,
            Pagination,
        },
        data() {
            return {
                transferActivity: [],
                currentPage: 1,
                fetching: false,
                loading: false,
                totalRows: 0,
                perPage: 5,
            };
        },
        computed: {
            transferId() {
                return this.$route.params.transferId;
            },
        },
        watch: {
            async currentPage() {
                await this.fetch('fetching');
            },
            async transferId() {
                await this.fetch('loading');
            },
        },
        async created() {
            await this.fetch('loading');
        },
        methods: {
            async fetch(action = 'loading') {
                try {
                    this[action] = true;
                    const result = await this.fetchActivity({
                        transferId: this.transferId,
                        limit: this.perPage,
                        page: this.currentPage,
                    }) || [];

                    this.transferActivity = result.activity;
                    this.totalRows = result.paging.count;
                } catch (err) {
                    utils.console.log(err);
                } finally {
                    this[action] = false;
                }
            },
            ...mapActions([
                'fetchActivity',
            ]),
        },
    };
</script>

<style
    lang="sass"
    scoped
>
    .transfer-activity-list
        .activity-details
            background-color: var(--kc-gray-200)
            border: 1px solid var(--kc-gray-300)
            border-radius: 0.375rem
            margin-top: 0.5rem
            padding: 1rem

    .mode.dark
        .transfer-activity-list .activity-details
            background-color: var(--kc-blue-800)
            border-color: var(--kc-blue-700)
</style>
